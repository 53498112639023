#loading-backdrop {
   position: relative;
   top: 0;
   left: 0;
   width: fit-content;
   height: fit-content;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 9999;
   margin: auto;
   margin-top: auto;
   padding: 50px;
 }
 
 #loading-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 
 #loading-logo {
   width: 90%; /* Adjust size as needed */
   height: 90%; /* Adjust size as needed */
   margin-bottom: 1rem;
 }
 
 @keyframes fadeIn {
   from {
     opacity: 0.5;
   }
   to {
     opacity: 1;
   }
 }
 
 #loading-text {
   color: green;
   font-size: 1.5rem;
   font-family: 'Helvetica Neue', sans-serif;
   animation: fadeIn 1s ease-in-out infinite alternate;
 }
 